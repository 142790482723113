import React, { useCallback } from "react";
import { useTranslate, useLocale, useSetLocale } from 'react-admin';
import {
  Slide,
  Dialog,
  DialogContent,
  Select,
  MenuItem,
  DialogTitle,
  Button,
  DialogActions,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const Transition = React.forwardRef((props, ref) => {
  return <Slide direction="up" ref={ref} {...(props as any)} />;
});

const supportedLocales = ['en', 'ja', 'ko', 'zh-TW', 'zh-CN'];

const useStyles = makeStyles((theme) => ({
  title: {
    flex: 1,
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
  },
  dialog: {
    minWidth: 300,
  },
  spacer: {
    flex: 1,
  },
  iconButton: {
    '& path': {
      fill: 'white'
    }
  }
}));

const LanguageSwitcherDialog = ({
  open,
  setOpen,
}: {
  open: boolean;
  setOpen: (open: boolean) => void;
}) => {
  const handleClose = useCallback(() => setOpen(false), [setOpen]);
  const translate = useTranslate();
  const locale = useLocale();
  const classes = useStyles();
  const setLocale = useSetLocale();
  const handleLanguageChange = useCallback((event: React.ChangeEvent<{
    name?: string;
    value: string;
  }>) => {
    document.querySelector("html").lang = event.target.value;
    setLocale(event.target.value);
  }, [setLocale])

  return (
    <Dialog
      open={open}
      TransitionComponent={Transition as any}
      keepMounted
      onClose={handleClose}
      aria-labelledby="alert-dialog-slide-title"
      aria-describedby="alert-dialog-slide-description"
    >
      <div className={classes.dialog}>
        <DialogTitle id="alert-dialog-slide-title">{translate('fields.language')}</DialogTitle>
        <DialogContent>
          <Select
            labelId="locale-selector-label"
            id="locale-selector"
            value={locale}
            onChange={handleLanguageChange}
          >
            {supportedLocales.map((locale) => (
              <MenuItem value={locale}>{translate(`fields.languages.${locale}`)}</MenuItem>
            ))}
          </Select>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            {translate('ra.action.close')}
          </Button>
        </DialogActions>
      </div>
    </Dialog>
  );
};

export default LanguageSwitcherDialog;

export default [
  [{ header: [1, 2, 3, false] }],

  [{ 'font': [] }],
  [{ 'align': [] }],

  ['bold', 'italic', 'underline', 'strike', 'link'],
  ['blockquote', 'code-block'],

  [{ list: 'ordered' }, { list: 'bullet' }],
  [{ script: 'super' }, { script: 'sub' }],
  [{ 'indent': '-1' }, { 'indent': '+1' }],
  [{ direction: 'rtl' }],

  [{ 'color': [] }, { 'background': [] }],

  ['image', 'video'],
  ['clean']
];
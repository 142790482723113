const defaultFallbackOrder = ['en', 'ja', 'vi', 'ko', 'zh-Hans', 'zh-Hant'];

export const fallbackToLocale = (obj: Record<string, any>, fallbackOrder: string[]) => {
  const fallbackOrderCount = fallbackOrder.length;
  for (let i = 0; i < fallbackOrderCount; i += 1) {
    const selectedLocale = fallbackOrder[i];
    if (obj[selectedLocale] && !Array.isArray(obj[selectedLocale])) {
      return obj[selectedLocale];
    }
    if (obj[selectedLocale] && Array.isArray(obj[selectedLocale]) && obj[selectedLocale].length) {
      return obj[selectedLocale];
    }
  }
  return null;
};

const pickByLocale = (
  obj: Record<string, any>,
  localeList: string[],
  fallbackOrder: string[] = defaultFallbackOrder,
) => {
  const localizedObject = {} as Record<string, any>;
  localeList.forEach((locale) => {
    if ((
      obj[locale] && Array.isArray(obj[locale]) && obj[locale].length
    ) || (
      obj[locale] && !Array.isArray(obj[locale])
    )) {
      localizedObject[locale] = obj[locale];
    } else {
      localizedObject[locale] = fallbackToLocale(obj, fallbackOrder);
    }
  });
  return localizedObject;
};

export default pickByLocale;

import { mergeWith } from 'lodash';
import { fetchUtils } from 'react-admin';
import simpleRestProvider from 'ra-data-simple-rest';
import mime from 'mime';

const customizer = (objValue, srcValue) => {
  if (Array.isArray(objValue)) {
    return objValue.concat(srcValue);
  }
};

const boothId = localStorage.getItem(`boothId_${process.env.NODE_ENV}`);
enum PROJECT_HOST {
  LOCAL = 'vexpo-product.2vanx.com',
  SERVE = 'vexpo-product.2vanx.com',
  STAGING = 'vexpo-product.2vanx.com',
  PRODUCTION = 'vexpo-product.eventxtra.com',
}

function validURL(str: string): boolean {
  var pattern = new RegExp('^((https|http)?:\\/\\/)?'+ // protocol
    '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+ // domain name
    '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
    '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
    '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
    '(\\#[-a-z\\d_]*)?$','i'); // fragment locator
  return !!pattern.test(str);
}

function dataURLtoBlob(dataurl: string): Blob {
  var arr = dataurl.split(','), mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]), n = bstr.length, u8arr = new Uint8Array(n);
  while(n--){
      u8arr[n] = bstr.charCodeAt(n);
  }
  return new Blob([u8arr], {type:mime});
}

let PROJECT_API_URL;
switch (window.location.host) {
  case PROJECT_HOST.PRODUCTION:
    PROJECT_API_URL = 'https://vexpo-product-api.eventxtra.com';
    break;
  case PROJECT_HOST.LOCAL:
  case PROJECT_HOST.SERVE:
    PROJECT_API_URL = 'https://vexpo-product-api.2vanx.com';
    break;
  case PROJECT_HOST.STAGING:
  default:
    PROJECT_API_URL = 'https://vexpo-product-api.2vanx.com';
}

const fetchWithoutCache = (url, options: Record<string, any> = {}) => {
  if (!options.headers) {
      options.headers = new Headers({
        'Cache-Control': 'no-cache',
        'x-apicache-bypass': "true",
        'x-eventxtra-admin': 'true',
      });
  }
  // add your own headers here
  options.headers.set('Cache-Control', 'no-cache');
  options.headers.set('x-eventxtra-admin', 'true');
  return fetchUtils.fetchJson(url, options);
}

export const API_URL = process.env.NODE_ENV === 'production' ? PROJECT_API_URL : 'https://vexpo-product-api.2vanx.com';
const dataProvider = simpleRestProvider(
  API_URL,
  fetchWithoutCache,
);

interface CMSImage {
  originalImageUrl: string;
  previewImageUrl: string;
}

const uploadRawImage = async (image: { rawFile: File }): Promise<CMSImage> => {
  const formData = new FormData();
  const rawFile = image.rawFile;
  formData.append("image", rawFile);
  const response = await fetch(`${API_URL}/images`, {
    method: 'POST',
    body: formData,
  });
  const [remoteFileUrl] = await response.json();
  return {
    originalImageUrl: remoteFileUrl,
    previewImageUrl: remoteFileUrl
  };
}

const productsDataProvider = {
  ...dataProvider,
  getList: async (resource, params) => {
    if (resource !== 'products') {
      return dataProvider.getList(resource, params);
    }
    return dataProvider.getList(resource, {
      ...params,
      filter: {
        ...params.filter,
        boothId: boothId || undefined
      },
      fields: '*'
    });
  },
  create: async (resource, params) => {
    if (resource !== 'products') {
      return dataProvider.create(resource, params);
    }
    const eventResponse = await fetch(`${API_URL}/events?boothId=${boothId}`, {
      headers: {
        'Cache-Control': 'no-cache',
        'x-apicache-bypass': "true",
        'x-eventxtra-admin': 'true',
      }
    });
    const { id: eventToken, fallbackLocale } = await eventResponse.json();
  
    const { images, details } = params.data;
    const updatedImages: {
      [locale: string]: CMSImage[]
    } = Object.keys(fallbackLocale).reduce((acc, key) => {
      acc[key] = [];
      return acc;
    }, {});
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    await Promise.all(Object.keys(images).map(async (locale) => {
      updatedImages[locale] = await Promise.all(
        images[locale].map(uploadRawImage)
      );
    }));

    const updatedDetails = {}
    await Promise.all(
      Object.keys(details).map(async (detailKey) => {
        const detailValue = details[detailKey];
        updatedDetails[detailKey] = { ...detailValue };
        await Promise.all(
          Object.keys(detailValue).map(async (currentLocale) => {
            const valueByLocale = detailValue[currentLocale];
            const { title, content } = valueByLocale || {};
            if (valueByLocale && content) {
              const dummyDiv = document.createElement('div');
              dummyDiv.innerHTML = content;
              const allImagesInDiv = dummyDiv.querySelectorAll("img");
              for (let i = 0; i < allImagesInDiv.length; i++) {
                const currentImage = allImagesInDiv[i];
                if (currentImage && !validURL(currentImage.src)) {
                  const imageBlob = dataURLtoBlob(currentImage.src);
                  const imageFile = new File([imageBlob], `temp.${mime.getExtension(imageBlob.type)}`);
                  const { originalImageUrl } = await uploadRawImage({ rawFile: imageFile });
                  allImagesInDiv[i].src = originalImageUrl;
                }
              }
              updatedDetails[detailKey][currentLocale] = {
                title,
                content: dummyDiv.innerHTML,
              };  
            }
          })
        )
      })
    );

    return dataProvider.create(resource, {
      data: {
        ...params.data,
        boothId,
        eventToken,
        images: updatedImages,
        details: updatedDetails,
      }
    });
  },
  update: async (resource, params) => {
    if (resource !== 'products' || !params.data.images) {
      // fallback to the default implementation
      return dataProvider.update(resource, params);
    }
    const { images, details } = params.data;
    const newPictures = Object.keys(images).reduce((acc, locale) => {
      acc[locale] = images[locale].filter(
        (localizedImage) => localizedImage.rawFile instanceof File
      );
      return acc;
    }, {});
    const formerPictures = Object.keys(images).reduce((acc, locale) => {
      acc[locale] = images[locale].filter(
        (localizedImage) => !(localizedImage.rawFile instanceof File)
      );
      return acc;
    }, {});

    const updatedDetails = {}
    await Promise.all(
      Object.keys(details).map(async (detailKey) => {
        const detailValue = details[detailKey];
        updatedDetails[detailKey] = { ...detailValue };
        await Promise.all(
          Object.keys(detailValue).map(async (currentLocale) => {
            const valueByLocale = detailValue[currentLocale];
            const { title, content } = valueByLocale || {};
            if (valueByLocale && content) {
              const dummyDiv = document.createElement('div');
              dummyDiv.innerHTML = content;
              const allImagesInDiv = dummyDiv.querySelectorAll("img");
              for (let i = 0; i < allImagesInDiv.length; i++) {
                const currentImage = allImagesInDiv[i];
                if (currentImage && !validURL(currentImage.src)) {
                  const imageBlob = dataURLtoBlob(currentImage.src);
                  const imageFile = new File([imageBlob], `temp.${mime.getExtension(imageBlob.type)}`);
                  const { originalImageUrl } = await uploadRawImage({ rawFile: imageFile });
                  allImagesInDiv[i].src = originalImageUrl;
                }
              }
              updatedDetails[detailKey][currentLocale] = {
                title,
                content: dummyDiv.innerHTML,
              };  
            }
          })
        )
      })
    );

    await Promise.all(
      Object.keys(newPictures).map(async (locale) => {
        newPictures[locale] = await Promise.all(
          newPictures[locale].map(uploadRawImage)
        );
      })
    );

    const updatedImages = mergeWith(formerPictures, newPictures, customizer);
    return dataProvider.update(resource, {
      ...params,
      data: {
        ...params.data,
        images: updatedImages,
        details: updatedDetails,
      }
    });
  },
}

export default productsDataProvider;

import React from 'react';
import { Admin, Resource } from 'react-admin';
import './App.css';
import polyglotI18nProvider from 'ra-i18n-polyglot';
import productsDataProvider from './components/DataProvider';
import CustomLayout from './components/CustomLayout';
import ProductsList from './pages/products';
import EditProduct from './pages/edit';
import LocaleObserver from './components/LocaleObserver';
import CreateProduct from './pages/create_product';
import EventDataContextStateProvider from './components/EventData';

import englishMessages from './i18n/en.json';

const defaultLocale = process.env.REACT_APP_DEFAULT_LOCALE;

const getLocaleTranslations = async (locale: string) => {
  const oneskyProjectId = process.env.REACT_APP_ONESKY_PROJECT_ID;
  const oneskyApiKey = process.env.REACT_APP_ONESKY_API_KEY;
  const oneskyUrl = `https://api.oneskyapp.com/2/string/output?platform-id=${oneskyProjectId}&api-key=${oneskyApiKey}&locale=${locale}`;
  const response = await fetch(oneskyUrl);
  const localeResponse = Object.values((await response.json())?.translation || {}).pop();
  return localeResponse[locale];
};

const getLocalization = (locale: string) => {
  if (locale !== defaultLocale) {
    return getLocaleTranslations(locale);
  }
  return englishMessages;
}

const i18nProvider = polyglotI18nProvider(
  getLocalization as any,
  defaultLocale,
);

const App = () => (
  <EventDataContextStateProvider>
    <Admin layout={CustomLayout} dataProvider={productsDataProvider as any} i18nProvider={i18nProvider}>
      <Resource
        name="products"
        list={ProductsList}
        edit={EditProduct}
        create={CreateProduct}
      />
      <LocaleObserver />
    </Admin>
  </EventDataContextStateProvider>
);

export default App;

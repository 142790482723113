import { useEffect } from 'react';
import { useLocale, useSetLocale } from 'react-admin';

const allowedOrigins = {
  'http://localhost:3002': true,
  'https://cms-virtual.2vanx.com': true,
  'https://cms-virtual.eventxtra.com': true,
  'https://cms-virtual-staging.eventx.io': true,
  'https://cms-virtual.eventx.io': true,
}

const LocaleObserver = () => {
  const currentLocale = useLocale();
  const setLocale = useSetLocale();

  useEffect(() => {
    const receiveMessage = (event: MessageEvent) => {
      if (!(event.origin in allowedOrigins))
        return;
      try {
        const { locale } = JSON.parse(event.data) || {};
        if (locale) {
          document.querySelector("html").lang = locale;
          setTimeout(() => setLocale(locale), 0);
        }
      } catch (e) {}
    }

    window.addEventListener("message", receiveMessage, false);

    return () => {
      window.removeEventListener('message', receiveMessage);
    }
  }, [currentLocale, setLocale])

  return null;
}

export default LocaleObserver;

import React, { createContext } from 'react';

const EventDataContext: React.Context<{
  fallbackLocale: string;
  supportedLocales: Record<string, boolean>;
  id: string;
  categories: any[];
  categoryLogic: any[];
  localeFallbackOrder?: string[];
  locales: string[];
}> = createContext({
  fallbackLocale: 'en',
  supportedLocales: {},
  id: null,
  categories: [],
  categoryLogic: [],
  locales: ['en']
});

export default EventDataContext;

import React, { useContext } from "react";
import {
  Create,
  FormTab,
  TextInput,
  ArrayInput,
  ImageInput,
  FormDataConsumer,
  NumberInput,
  ImageField,
  TabbedForm,
  CheckboxGroupInput,
  SimpleFormIterator,
  minValue,
} from 'react-admin';
import RichTextInput from 'ra-input-rich-text';
import EventDataContext from "../../components/EventData/context";
import DefaultRichtextToolbar from "../../components/DefaultRichtextToolbar";
import pickByLocale from "../../components/pickByLocale";

const FormFields = ({ locale }: {
  locale: string
}) => {
  const { categories, localeFallbackOrder, supportedLocales } = useContext(EventDataContext);
  const firstLevelCategoriesMap = (categories || []).reduce((acc, cat) => {
    acc[cat.id] = cat;
    return acc;
  }, {});
  const initialCategories = (categories || []).map((cat) => ({
    ...cat,
    subcategories: [],
  }));
  const localeFallback = Object.keys(supportedLocales);

  return (
    <>
      <NumberInput
        min={1}
        validate={minValue(1)}
        source="sort"
        label="fields.sort"
      />
      <TextInput
        source={`name.${locale}`}
        label="fields.name"
        fullWidth
        initialValue=""
      />
      <ImageInput
        source={`images.${locale}`}
        label="fields.images"
        accept="image/*"
        multiple
        initialValue={[]}
      >
        <ImageField source="originalImageUrl" title="title" />
      </ImageInput>
      <RichTextInput
        source={`details.description.${locale}.content`}
        label="fields.description"
        initialValue=""
        toolbar={DefaultRichtextToolbar}
      />
      {categories && (
        <ArrayInput
          source="categories"
          label="fields.categories"
          key="categories"
          initialValue={initialCategories}
        >
            <SimpleFormIterator disableAdd disableRemove>
              <FormDataConsumer>
                {({ getSource, scopedFormData, formData }) => {
                  const { id, label } = scopedFormData || {};
                  return (
                    <CheckboxGroupInput
                      fullWidth
                      emptyText="None"
                      optionText={`label.${locale}`}
                      optionValue="id"
                      row={false}
                      label={pickByLocale(label, localeFallback, localeFallbackOrder)[locale]}
                      source={getSource('subcategories')}
                      choices={firstLevelCategoriesMap[id]?.subcategories?.map((cat) => ({
                        ...cat,
                        label: pickByLocale(cat.label || {}, localeFallback, localeFallbackOrder)
                      })) ?? []}
                    />
                  );
                }}
              </FormDataConsumer>
            </SimpleFormIterator>
        </ArrayInput>
      )}
      <ArrayInput
        source={`tags.${locale}`}
        label="fields.tags"
        key={locale}
        initialValue={[]}
      >
        <SimpleFormIterator>
          <TextInput fullWidth label={false} source="" />
        </SimpleFormIterator>
      </ArrayInput>
    </>
  );
};

const CreateProduct = (props) => {
  const { locales } = useContext(EventDataContext);

  return (
    <Create {...props}>
      <TabbedForm>
        {locales.map((locale) => (
          <FormTab key={locale} label={`fields.languages.${locale}`}>
            <FormFields locale={locale} />
          </FormTab>
        ))}
      </TabbedForm>
    </Create>
  );
};

export default CreateProduct;

import React, { useState } from "react";
import { AppBar } from 'react-admin';
import {
  Typography, IconButton,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import LanguageSwitcherDialog from './LanguageSwitcherDialog';

const useStyles = makeStyles((theme) => ({
  title: {
    flex: 1,
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
  },
  spacer: {
    flex: 1,
  },
  iconButton: {
    '& path': {
      fill: 'white'
    }
  }
}));

const CustomAppBar = props => {
  const classes = useStyles();
  const [dialogOpen, setDialogOpen] = useState(false);

  const onLanguageSwitcherClick = (_: React.MouseEvent) => {
    setDialogOpen(true);
  }

  return (
    <AppBar {...props}>
      <LanguageSwitcherDialog open={dialogOpen} setOpen={setDialogOpen} />
      <Typography
        variant="h6"
        color="inherit"
        className={classes.title}
        id="react-admin-title"
      />
      <span className={classes.spacer} />
      {process.env.NODE_ENV !== 'production' && (
        <IconButton onClick={onLanguageSwitcherClick} />
      )}
    </AppBar>
  );
};

export default CustomAppBar;

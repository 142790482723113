import React, { useState, useEffect } from 'react';
import EventDataContext from './context';
import { API_URL } from '../DataProvider';

const parseQueryString = (queryString: string) => queryString.split("&").reduce((acc: Record<string, string>, kvPair: string) => {
  const [key, value] = kvPair.split("=");
  acc[key] = value;
  return acc;
}, {});

function stripQueryStringAndHashFromPath(url: string) {
  return url.split("?")[0].split("#")[0];
}

const EventDataContextStateProvider = React.memo(({ children }: {
  children: React.ReactElement | React.ReactElement[]
}) => {
  const [eventData, setEventData] = useState(null);
  const [boothId, setBoothId] = useState(undefined);

  useEffect(() => {
    if (!boothId) return;
    fetch(`${API_URL}/events?boothId=${boothId}`, {
      headers: {
        'Cache-Control': 'no-cache',
        'x-apicache-bypass': "true",
        'x-eventxtra-admin': 'true',
      }
    })
      .then(async (res) => {
        if (res.status === 200) {
          const data = await res.json();
          data.locales = Object.keys(data.supportedLocales).sort();
          setEventData(data);

          // eslint-disable-next-line no-restricted-globals
          if (parent) {
            // eslint-disable-next-line no-restricted-globals
            parent.postMessage(JSON.stringify({ eventLoaded: true }), '*');
          }
        } else {
          setEventData({});
        }
      })
  }, [boothId]);

  useEffect(() => {
    const queryString = window.location.search.substring(1);
    const { boothId: queriedBoothId } = parseQueryString(queryString);
    if (queriedBoothId) {
      localStorage.setItem(`boothId_${process.env.NODE_ENV}`, queriedBoothId);
      window.location.replace(stripQueryStringAndHashFromPath(window.location.href));
    }
    setBoothId(
      queriedBoothId || localStorage.getItem(`boothId_${process.env.NODE_ENV}`) || null
    );
  }, []);

  if (!eventData) {
    return <div>Fetching event data...</div>;
  }

  return (
    <EventDataContext.Provider value={eventData}>
      {children}
    </EventDataContext.Provider>
  );
})

export default EventDataContextStateProvider;

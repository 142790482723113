import React, { useContext } from 'react';
import { List, Datagrid, TextField, SimpleList } from 'react-admin';
import { useMediaQuery } from '@material-ui/core';
import EventDataContext from '../../components/EventData/context';

const ProductsList = (props: any) => {
  const { supportedLocales } = useContext(EventDataContext);
  const sortedLocales = Object.keys(supportedLocales).sort();
  const isSmall = useMediaQuery((theme: any) => theme.breakpoints.down('sm'));
  return (
    <List {...props} >
      {isSmall ? (
        <SimpleList
          primaryText={record => record?.name?.ja}
          secondaryText={record => record?.name?.en}
        />
        ) : (
          <Datagrid
            rowClick="edit"
            optimized
          >
            <TextField source={`sort`} />
            {sortedLocales.map((locale) => (
              <TextField source={`name.${locale}`} />
            ))}
          </Datagrid>
      )}
    </List>
  );
}

export default ProductsList;
